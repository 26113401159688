<template>
  <div v-if="this.show" class="small-menu">
    <div class="top-container">
      <img
        class="close-button btn-cursor"
        src="../assets/image/small_menu_close.svg"
        @click="$emit('onClose')"
      />
      <router-link
        :to="`${'/' + this.GLOBAL.currentLanguage + '/pricing'}`"
        class="menu-item btn-cursor"
        :class="menuSelected(1)"
      >
        {{ $t("header_pricing") }}
      </router-link>
      <a
        class="menu-item btn-cursor"
        :class="menuSelected(2)"
        @click="this.showApps = !this.showApps"
      >
        {{ $t("header_apps") }}
        <img
          src="../assets/icons/expand_more.svg"
          class="apps-arrow"
          :style="{
            transform: showApps ? 'rotate(0deg)' : 'rotate(-90deg)',
          }"
        />
      </a>
      <div class="apps-container" v-show="showApps">
        <router-link
          :to="`${'/' + this.GLOBAL.currentLanguage + '/apps?platform=0'}`"
          class="apps-item btn-cursor"
          @click="$emit('onClose')"
        >
          Android
        </router-link>
        <router-link
          :to="`${'/' + this.GLOBAL.currentLanguage + '/apps?platform=1'}`"
          class="apps-item btn-cursor"
          @click="$emit('onClose')"
        >
          Windows
        </router-link>
        <router-link
          :to="`${'/' + this.GLOBAL.currentLanguage + '/apps?platform=2'}`"
          class="apps-item btn-cursor"
          @click="$emit('onClose')"
        >
          Mac
        </router-link>
      </div>
      <a
        href="https://shellvpn.tawk.help/"
        class="menu-item btn-cursor"
        @click="$emit('onClose')"
      >
        {{ $t("header_support") }}
      </a>
      <a
        href="https://blog.surfshell.io/"
        class="menu-item btn-cursor"
        @click="$emit('onClose')"
      >
        {{ $t("header_blog") }}
      </a>
      <router-link
        :to="`${'/' + this.GLOBAL.currentLanguage + '/login'}`"
        class="menu-item btn-cursor"
        v-show="!this.checkAuth()"
      >
        {{ $t("header_sign") }}
      </router-link>
      <router-link
        :to="`${'/' + this.GLOBAL.currentLanguage + '/account'}`"
        class="menu-item btn-cursor"
        v-show="this.checkAuth()"
      >
        <span>My Account</span>
      </router-link>
    </div>
    <router-link
      class="btn-container"
      :to="`${'/' + this.GLOBAL.currentLanguage + '/pricing'}`"
    >
      <button
        type="button"
        class="menu-btn btn-radius-format-1 btn-color-1 btn-cursor"
      >
        <span>{{ $t("header_get_started") }}</span>
      </button>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "SmallMenu",
  props: { show: Boolean },
  emits: ["onClose"],
  data() {
    return {
      selectedMenu: 0,
      showApps: false,
    };
  },
  mounted() {
    this.getSelectedMenu();
  },
  methods: {
    getSelectedMenu() {
      const path = this.$route.path.replace(
        "/" + this.GLOBAL.currentLanguage,
        ""
      );
      switch (path) {
        case "/index":
        case "/":
          this.selectedMenu = 0;
          break;
        case "/pricing":
          this.selectedMenu = 1;
          break;
        case "/apps":
          this.selectedMenu = 2;
          break;
        default:
          break;
      }
    },
    menuSelected(index) {
      if (this.selectedMenu == index) {
        return "menu-selected";
      }
      return "";
    },
  },
};
</script>

<style scoped>
.small-menu {
  width: 230px;
  min-height: 100vh;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.top-container {
  display: flex;
  flex-direction: column;
}

.close-button {
  width: 22px;
  height: 22px;
  margin-top: 22px;
  margin-right: 15px;
  align-self: flex-end;
}

.menu-item {
  font-size: 14px;
  color: #1d1d1d;
  margin-top: 30px;
  margin-left: 32px;
}

.menu-selected {
  color: #4ebe86;
}

.apps-arrow {
  width: 10px;
  margin-left: 4px;
  margin-bottom: 1px;
}

.apps-container {
  display: flex;
  flex-direction: column;
}

.apps-item {
  font-size: 14px;
  color: #1d1d1d;
  margin-top: 30px;
  margin-left: 64px;
}

.btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-btn {
  width: 180px;
  height: 40px;
  margin-top: 60px;
  margin-bottom: 20px;
}
</style>
