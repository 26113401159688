<template>
  <div class="pricing">
    <div class="logo-box">
      <router-link :to="`${'/' + this.GLOBAL.currentLanguage + '/'}`">
        <img alt="" src="../assets/image/logo.svg" class="logo" />
      </router-link>
      <img
        class="small_menu_open btn-cursor"
        src="../assets/image/small_menu_open.svg"
        @click="this.isShowSmallMenu = true"
      />
      <SmallMenu
        :show="isShowSmallMenu"
        @onClose="() => (this.isShowSmallMenu = false)"
      />
    </div>
    <div class="page-1" v-show="!isShowPaymentBox">
      <div class="box-1">
        <div class="box-1-title">1.Choose a plan</div>
        <div
          class="box-1-package-box package-bottom-space btn-cursor"
          v-for="paymentPackage in packages"
          :key="paymentPackage.id"
          :class="{ 'package-active': selectedPackageId === paymentPackage.id }"
          @click="selectPackage(paymentPackage)"
        >
          <div class="packages-box-left">
            <div class="package-name">{{ paymentPackage.name }}</div>
            <div class="package-price-unit">
              ${{ paymentPackage.unitPrice.price }}/{{
                paymentPackage.unitPrice.unit
              }}
            </div>
            <div class="packages-total-description">
              <span class="packages-total"
                >${{ originalTotalPrice(paymentPackage) }}</span
              >
              <span class="package-description">{{
                paymentPackage.show.description
              }}</span>
            </div>
          </div>
          <div class="packages-box-right" v-show="paymentPackage.discountRatio">
            <div class="packages-box-discount-text">
              <span class="packages-box-save">save</span>
              <span class="packages-box-discount">{{
                paymentPackage.discountRatio
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="box-2">
        <div class="premium-includes">
          <div class="premium-includes-title">
            {{ $t("pricing_premium_includes") }}
          </div>
          <div>
            <div class="premium-includes-line">
              <div>
                <img
                  class="icon-correct"
                  src="../assets/icons/pricing-correct.svg"
                />
              </div>
              <div class="premium-includes-description">
                {{ $t("pricing_advanced") }}
              </div>
            </div>
            <div class="premium-includes-line">
              <div>
                <img
                  class="icon-correct"
                  src="../assets/icons/pricing-correct.svg"
                />
              </div>
              <div class="premium-includes-description">
                {{ $t("pricing_optimized") }}
              </div>
            </div>
            <div class="premium-includes-line">
              <div>
                <img
                  class="icon-correct"
                  src="../assets/icons/pricing-correct.svg"
                />
              </div>
              <div class="premium-includes-description">
                {{ $t("pricing_locations") }}
              </div>
            </div>
            <div class="premium-includes-line">
              <div>
                <img
                  class="icon-correct"
                  src="../assets/icons/pricing-correct.svg"
                />
              </div>
              <div class="premium-includes-description">
                {{ $t("pricing_streaming_with_unlimited_data") }}
              </div>
            </div>
            <div class="premium-includes-line">
              <div>
                <img
                  class="icon-correct"
                  src="../assets/icons/pricing-correct.svg"
                />
              </div>
              <div class="premium-includes-description">
                {{ $t("pricing_live_support") }}
              </div>
            </div>
            <div class="premium-includes-line">
              <div>
                <img
                  class="icon-correct"
                  src="../assets/icons/pricing-correct.svg"
                />
              </div>
              <div class="premium-includes-description">
                {{ $t("pricing_devices") }}
              </div>
            </div>
          </div>
          <img
            alt=""
            src="../assets/image/pricing_stars.svg"
            class="icon-pricing-star"
          />
        </div>
      </div>
      <button
        type="button"
        class="continue-btn btn-color-1 btn-cursor"
        @click="showPaymentBox"
      >
        Continue to Payment
      </button>
    </div>
    <div class="page-2" v-show="isShowPaymentBox">
      <div class="payment" v-show="!this.checkAuth()">
        <div class="payment-title">
          <span>2.Payment</span>
        </div>
        <div class="create-account-input-box">
          <el-input
            tabindex="2"
            type="text"
            v-model="email"
            :placeholder="$t('pricing_step2_email')"
          >
          </el-input>
        </div>
        <div class="create-account-input-box password-input-box">
          <el-input
            :key="passwordType"
            ref="password"
            v-model="password"
            class="form-pwd__input"
            :type="passwordType"
            :placeholder="$t('pricing_step2_password')"
            name="password"
            tabindex="3"
            auto-complete="on"
          >
            <template #suffix>
              <span class="show-or-hide-password" @click="showOrHidePassword">
                <img
                  class="show-or-hide-pwd-icon"
                  :src="
                    passwordType === 'password'
                      ? require('../assets/icons/form_hide_pwd.svg')
                      : require('../assets/icons/form_show_pwd.svg')
                  "
                />
              </span>
            </template>
          </el-input>
        </div>
        <div class="email-tip" v-if="email">
          We will not share your information or send spam
        </div>
      </div>
      <div class="select-payment">
        <div class="select-payment-title">
          {{ this.checkAuth() ? "2" : "3" }}.Select payment
        </div>
        <!-- <div
          class="payment-method-line btn-cursor"
          :class="{ 'is-active': currentPaymentMethod === 'yuansfer' }"
          @click="changePaymentMethod('yuansfer')"
        >
          <img
            alt=""
            src="../assets/image/pricing_payment_alipay.svg"
            class="payment-icon-alipay"
          />
          <div class="active-box">
            <img
              src="../assets/image/promote-correct.svg"
              class="active-icon"
              alt=""
            />
          </div>
        </div> -->
        <div
          class="payment-method-line btn-cursor"
          :class="{ 'is-active': currentPaymentMethod === 'stripe' }"
          @click="changePaymentMethod('stripe')"
        >
          <img
            alt=""
            src="../assets/image/pricing_payment_stripe.svg"
            class="payment-icon-card"
          />
          <div class="active-box">
            <img
              src="../assets/image/promote-correct.svg"
              class="active-icon"
              alt=""
            />
          </div>
        </div>
        <div
          class="payment-method-line btn-cursor"
          :class="{ 'is-active': currentPaymentMethod === 'paypal' }"
          @click="changePaymentMethod('paypal')"
        >
          <img
            alt=""
            src="../assets/image/pricing_payment_paypal.svg"
            class="payment-icon-paypal"
          />
          <div class="active-box">
            <img
              src="../assets/image/promote-correct.svg"
              class="active-icon"
              alt=""
            />
          </div>
        </div>
        <div class="purchase-tip">
          By submitting this form<br />
          you agree to our
          <router-link :to="`${'/' + this.GLOBAL.currentLanguage + '/term'}`"
            ><span> Terms of Service</span>
          </router-link>
          and
          <router-link
            :to="`${'/' + this.GLOBAL.currentLanguage + '/privacy_policy'}`"
            ><span> Privacy Policy</span>
          </router-link>
        </div>
      </div>
      <div class="payment-row">
        <div class="order-info">
          <div class="select-package-title">
            {{ selectedPackage.name }}
          </div>
          <div class="select-package-price">
            {{ selectedPackage.price }}
          </div>
        </div>
        <button
          type="button"
          class="payment-btn btn-flex-center btn-color-1 btn-cursor"
          @click="confirmPayment"
          :disabled="this.duringPayment"
          :class="{ 'btn-not-allowed': this.duringPayment }"
        >
          <img
            alt=""
            src="../assets/image/pricing_mobile_payment-btn.png"
            class="payment-btn-img"
          />
          <span>Buy Now</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SmallMenu from "../components/SmallMenu.vue";

export default {
  name: "Pricing",
  components: { SmallMenu },
  data() {
    return {
      isShowSmallMenu: false,
      timeInterval: null,
      duringPayment: false,
      packageId: 0,
      isShowPaymentBox: false,
      currentPaymentMethod: "yuansfer",
      email: null,
      password: null,
      passwordType: "password",
      selectedPackageId: 0,
      selectedPackage: {
        isMock: true,
        id: 19,
        name: "1 Year ",
        deviceCount: 5,
        currency: "USD",
        price: "40.00",
        unitPrice: {
          price: "3.33",
          unit: "month",
        },
        isLimitTimeDiscount: false,
        discountRatio: "67%",
        options: [],
        show: {
          time: "12 Months",
          timeFree: null,
          originalPrice: "119.88",
          description: "Billed $40.00 for 12 months",
        },
      },
      packages: [],
    };
  },
  created() {
    // this.timeIntervalOnResize();
    this.packageId =
      this.$route.query.packageId === undefined
        ? 0
        : this.$route.query.packageId;
    if (this.packageId !== undefined && this.packageId.length !== 0) {
      this.selectedPackageId = parseInt(this.packageId);
    }
    this.getPackages();
  },
  methods: {
    getPackages() {
      this.$axios.get("topup-packages").then((response) => {
        this.packages = response.data;
        if (this.selectedPackageId) {
          this.selectedPackage = this.getPackageById(this.selectedPackageId);
        }
      });
    },
    getPackageById(packageId) {
      for (let index in this.packages) {
        if (this.packages[index].id === packageId) {
          return this.packages[index];
        }
      }
      return null;
    },
    selectPackage(paymentPackage) {
      this.selectedPackage = paymentPackage;
      this.selectedPackageId = paymentPackage.id;
    },
    showOrHidePassword() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    changePaymentMethod(value) {
      this.currentPaymentMethod = value;
    },
    showPaymentBox() {
      if (this.selectedPackage.isMock) {
        this.$message.error("please select a purchase plan");
        return false;
      }
      history.pushState({ page: 2 }, "page-2", location.href);
      this.isShowPaymentBox = true;
    },
    confirmPayment() {
      /* Prevent repeated clicks */
      if (this.duringPayment) {
        return false;
      }
      if (this.checkAuth()) {
        this.duringPayment = true;
        this.payOrder();
      } else {
        if (this.checkPurchaseForm()) {
          this.duringPayment = true;
          this.registerAndPayOrder();
        }
      }
    },
    payOrder() {
      this.$axios
        .post("/v1/order", {
          packageId: this.selectedPackage.id,
          paymentMethod: this.currentPaymentMethod,
          webDomain: this.GLOBAL.getWebDomain(),
          terminal: this.GLOBAL.isMobile() ? "wap" : "pc",
        })
        .then((response) => {
          this.afterPayOrder(response.data.detail);
          // eslint-disable-next-line handle-callback-err
        })
        .catch(() => {
          this.duringPayment = false;
        });
    },
    registerAndPayOrder() {
      let postData = {
        email: this.email,
        password: this.password,
        packageId: this.selectedPackage.id,
        paymentMethod: this.currentPaymentMethod,
        webDomain: this.GLOBAL.getWebDomain(),
      };
      this.$axios
        .post("/auth/register-package", postData)
        .then((response) => {
          this.afterPayOrder(response.data.detail);
          // eslint-disable-next-line handle-callback-err
        })
        .catch(() => {
          this.duringPayment = false;
        });
    },
    afterPayOrder(detail) {
      if (this.currentPaymentMethod !== "stripe") {
        if (detail.approval) {
          window.location.href = detail.approval;
        }
      } else {
        this.stripeCardCheckout(detail.id);
      }
      this.duringPayment = false;
    },
    checkPurchaseForm() {
      if (this.email === null) {
        this.$message.error("please input email");
        return false;
      }
      if (this.password === null) {
        this.$message.error("please enter the password as required");
        return false;
      }
      return this.checkPassword();
    },
    checkPassword() {
      let passwordCheckLengthCorrect =
        this.password !== null &&
        this.password.length >= 8 &&
        this.password.length <= 16;
      if (passwordCheckLengthCorrect) {
        return true;
      } else {
        this.$message.error("password's length must between 8 and 16");
        return false;
      }
    },
    stripeCardCheckout(orderId) {
      // eslint-disable-next-line no-undef
      stripe
        .redirectToCheckout({
          sessionId: orderId,
        })
        .then(function (response) {
          console.log(response);
        });
    },
    originalTotalPrice(selectedPackage) {
      if (selectedPackage.show.originalPrice) {
        return selectedPackage.show.originalPrice;
      }
      if (selectedPackage.discountRatio) {
        return (
          (selectedPackage.price /
            (100 - selectedPackage.discountRatio.replace("%", ""))) *
          100
        ).toFixed(2);
      }
      return selectedPackage.price;
    },
    listeningBack() {
      if (this.isShowPaymentBox) {
        this.isShowPaymentBox = false;
      }
    },
  },
};
</script>

<style scoped>
.pricing {
  display: flex;
  flex-direction: column;
  background: #ffffff;
}

.logo-box {
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.logo {
  width: 81px;
  height: 21px;
  margin: 0 15px;
}

.small_menu_open {
  width: 22px;
  height: 22px;
  margin-right: 15px;
}

.page-1 {
  display: flex;
  flex-direction: column;
}

.box-1 {
  background-color: #fafafa;
  padding: 16px 15px;
}

.box-1-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #141c49;
  margin-bottom: 12px;
}

.box-1-package-box {
  padding: 19px 21px 18px 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.package-bottom-space {
  margin-bottom: 6px;
}

.package-name {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #1d1d1d;
}

.package-price-unit {
  margin-top: 7px;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  color: #1d1d1d;
}

.packages-total-description {
  margin-top: 7px;
}

.packages-total {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: #ff0000;
  text-decoration: line-through;
}

.package-description {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: #a8a8a8;
}

.packages-box-right {
  width: 55px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../assets/image/pricing_mobile-discount-bg.png") center 0;
  background-size: 100% 100%;
}

.packages-box-discount-text {
  text-align: center;
  transform: rotate(-30deg);
  transform-origin: center;
  display: flex;
  flex-direction: column;
}

.packages-box-save {
  font-size: 11px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 12px;
  color: #1d1d1d;
}

.packages-box-discount {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 13px;
  color: #1d1d1d;
}

.package-active {
  border: 1px solid #4ebe86;
}

.box-2 {
  padding: 30px 26px 41px;
  background: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.premium-includes-title {
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  color: #1d1d1d;
  margin-bottom: 16px;
}

.premium-includes-line {
  margin-bottom: 14px;
  display: flex;
}

.premium-includes-description {
  margin-left: 7px;
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  color: #1d1d1d;
}

.icon-correct {
  width: 13px;
  height: 13px;
}

.icon-pricing-star {
  width: 161px;
  height: 17px;
  margin-top: 4px;
}

.continue-btn {
  width: 100%;
  height: 59px;
  border-radius: 4px 4px 0 0;
  font-size: 14px;
  font-weight: bold;
}

.page-2 {
  padding-bottom: 65px;
}

.payment {
  padding: 9px 15px;
  background: #ffffff;
}

.payment-title {
  margin: 8px 0 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #141c49;
}

.create-account-input-box {
  width: 100%;
  height: 44px;
  background: #ffffff;
  margin-bottom: 10px;
  line-height: 44px;
}

.create-account-input-box :deep(.el-input__suffix) {
  margin-right: 15px;
}

.show-or-hide-password {
  line-height: 44px;
}

.show-or-hide-pwd-icon {
  width: 17px;
}

.create-account-input-box :deep(.el-input__inner) {
  width: 100%;
  height: 44px;
  padding: 0 15px;
  font-size: 14px;
  font-weight: 300;
  color: #aaaaaa;
  border-radius: 4px;
  border: 1px solid #aaaaaa;
}

.password-input-box :deep(.el-input__inner) {
  border-bottom: 2px solid #ff0000;
}

.email-tip {
  margin-top: 3px;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: #1d1d1d;
}

.create-account-input-box :deep(.el-input__inner:focus) {
  border-color: #ff0000;
}

.select-payment {
  background: #ffffff;
  overflow: hidden;
  padding: 0 15px;
  margin-bottom: 65px;
}

.select-payment-title {
  margin: 16px 0 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #141c49;
}

.payment-method-line {
  height: 60px;
  background: #ffffff;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px 0 13px;
}

.payment-icon-alipay {
  max-width: 30%;
}

.payment-icon-card {
  max-width: 80%;
}

.payment-icon-paypal {
  max-width: 30%;
}

.active-box {
  width: 17px;
  height: 17px;
  background: #f2f4f7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.is-active {
  border: 1px solid #4ebe86;
}

.is-active .active-box {
  background: #4ebe86;
}

.is-active .active-icon {
  display: block;
}

.active-icon {
  height: 6px;
  display: none;
}

.purchase-tip {
  margin-top: 2px;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  color: #1d1d1d;
}

.payment-row {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  position: fixed;
  bottom: 0;
  left: 0;
}

.order-info {
  width: 40%;
  padding: 11px 0;
  background: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.select-package-title {
  font-size: 12px;
  font-weight: 400;
  color: #1d1d1d;
}

.select-package-price {
  font-size: 16px;
  font-weight: bold;
  color: #1d1d1d;
}

.payment-btn {
  width: 60%;
  border-radius: 0 4px 0 0;
}

.payment-btn-img {
  height: 39px;
}

.payment-btn span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}
</style>
